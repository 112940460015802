<template>
  <div class="loan_item">
    <div class="inner inner1">
      <div class="content">
        <div class="left_cell">
          <h2>Borrow and <span>grow your loan limits</span></h2>
          <p class="inner1_text">Instant online personal loans up to ₦500,000. No salary slip, bank statement or credit score needed (100% paperless). Simply download the app, verify your phone number, answer a few questions & submit your application. Takes only 5 minutes to apply. Once our loan app approves your loan application, money is sent directly to your bank account. Convenient repayment schedule with more than 90 days to repay. To get started, download the HelaCash loan app now!
          </p>
        </div>
        <div class="right_cell">
          <!-- <img src="~@/assets/images/loanImg/about_us.png"> -->
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <h1>Flexible loans to fit your life</h1>
      <div class="items">
        <div class="left_item">
          <img src="@/assets/images/loanImg/icon_people.png" alt="">
        </div>
        <div class="right_item">
          <div class="item_info">
            <div class="info_tips">
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-5.png" alt="">
                <div>
                  <h3>No salary slip needed</h3>
                  <p>Apply for a loan in the HelaCash loan app.  Only fill out a short application
                    and verify mobile phone number are needed.</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-6.png" alt="">
                <div>
                  <h3>Instant approval</h3>
                  <p>Get a decision instantly. Once approved, funds are sent straight
                    to your account</p>
                </div>
              </div>
              <div class="items">
                <img class="info_img1" src="@/assets/images/loanImg/app-icon-7.png" alt="">
                <div>
                  <h3>Double your limit in months</h3>
                  <p>Most customers double their limit in just two months. Limits
                    grow to meet your needs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="left_item">
        <h1>Features to help you grow</h1>
        <p>Our app makes it easy to pursue your financial goals, whatever they are.</p>
        <div @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <div class="item_info">
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-8.png" alt="">
            <h4 class="bottom-20">No hidden costs</h4>
            <p>ransparent and affordable. </p>
            <p>Know exactly what is due from the start.</p>
            <p>&nbsp;</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-9.png" alt="">
            <h4 class="bottom-20">Easy repayment</h4>
            <p>Pay easily in the app.</p>
            <p>Choose to pay with debit card, </p>
            <p>netbanking, or wallets.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-10.png" alt="">
            <h4 class="bottom-20">Trusted & secure</h4>
            <p>We will never call your contacts. </p>
            <p>Your contacts and other information</p>
            <p>are always private and protected.</p>
          </div>
          <div class="info_tips">
            <img class="info_img1" src="@/assets/images/loanImg/app-icon-11.png" alt="">
            <h4 class="bottom-20">Customer care</h4>
            <p>Our friendly customer service team is always</p>
            <p>here to help. Reach out and a real person will</p>
            <p>assist you.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=fastcredit.instahela.app'
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #333;
        line-height: 25px;
        margin: 40px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    padding: 120px 0;
    background: url("~@/assets/images/loanImg/inner1_2.png") no-repeat;
    background-size: 100% 100%;
    .content{
      display: flex;
      padding: 0px calc((100% - 1300px) / 2) 0;
      .left_cell,.right_cell{
        flex: 1;
      }
      .left_cell{
        padding: 75px 50px 20px 50px;
        background: linear-gradient(to bottom,#ED7E00 0% ,#ED7E00 5%, #A4C519 5% ,#A4C519 100%);
        h2{
          text-align: center;
          span{
            color: white;
          }
        }
      }
      .right_cell{
        flex: 1.5;
        img{
          margin: 0px auto 25px!important;
          width: 500px;
          height: 440px;
          display: block;
        }
      }
    }
  }
  .inner2{
    // height: 420px;
    background:#EEEEEE;
    text-align: center;
    padding:50px calc((100% - 1100px) / 2) 0;
    h1{
      margin-bottom: 60px;
    }
    .items{
      display: flex;
      }
    .left_item{
      flex: 1;
      text-align: start;
      img{
        width: 60%;
      }
    }
    .right_item{
      flex: 1;
      margin-right:30px;
      text-align: left;
      line-height: 30px;
      padding-bottom: 100px;
      p{
        color:#2665D9 ;
        font-size: 20px;
        font-weight: bold;
      }
      .item_tips{
        display: flex;
        width: 460px;
        p{
          width: 70px;
          font-size: 12px;
          color: #777777;
          text-align: left;
        }
      }
      .item_info{
        // margin: 20px 0;
        height: 100%;
        display: flex;
        .info_img1{
          width: 50px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .items{
            flex: 1;
            display: flex;
            background: white !important;
            box-shadow: 0 0 2px #999;
            box-sizing: border-box;
            padding: 10px 60px 10px 20px;
            border-radius: 10px;
            align-items: center;
            margin-bottom: 30px;
            img{
              margin-right: 10px;
            }
          }
          .items:nth-last-child(1){
            margin-bottom: 0px !important;
          }
          p{
            font-size: 12px;
            color: #666666;
            line-height: 20px;
          }
        }
      }
    }
  }
  .inner3{
    background:#F8F9FF;
    display: flex;
    flex-direction: column;
    height: 550px;
    padding:50px calc((100% - 1100px) / 2) 0;
    .left_item{
      flex: 1;
      background: url("~@/assets/images/loanImg/inner2_bg.png") no-repeat;
      background-size: 100% auto;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1{
        // margin-left: 30px;
        // text-align: center;
        width: 400px;
        font-weight: bold;
        line-height: 40px;
        font-size: 30px;
        margin-bottom: 20px;
      }
      div{
        position: absolute;
        right: 20px;
        top: calc(50% - 20px);
        img{
          display: block;
          width: 150px;
          margin: 0 auto;
        }
        p{
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
    .right_item{
      flex: 1.6;
      h1{
        font-size: 38px;
        line-height: 47px;
      }
      p{
        // margin-top: 20px;
        color:#2665D9 ;
        font-size: 20px;
        font-weight: bold;
      }
      .item_info{
        display: flex;
        background: white;
        .info_img1{
          width: 30px;
          height: 30px;
          margin-bottom: 25px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          background: #2E373D;
          margin-right: 5px;
          flex: 1;
          align-items: center;
          justify-content: center;
          padding: 30px 8px 25px;
          text-align: center;
          h4{
            color: white;
            line-height: 20px;
            font-weight: normal;
          }
          p{
            display: flex;
            box-sizing: border-box;
            font-size: 12px;
            color: #ffffff99;
            align-items: center;
            line-height: 18px;
            font-weight: normal;
            img{
              margin-right: 10px;
            }
          }
          & h4:nth-last-child(1){
            margin-bottom:20px;
          }
        }
        & .info_tips:nth-last-child(1){
          margin-right: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      padding: 30px 0;
      background: white !important;
      h2{
        padding: 20px;
        font-size: 18px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          padding: 20px 0;
        }
        .cell_item{
          width: 100% !important;
          height: 100px;
        }
        .inner1_text{
          margin: 0;
          padding: 0 20px;
        }
        img{
          margin-top: 0px;
          width: 300px !important;
          height: 300px !important;
          margin: 0 auto ;
        }
      }
    }
    .inner2{
      padding: 20px calc((100% - 1100px) / 2) 0;
      width: calc(100vw);
      h1{
        line-height: 40px;
        margin-bottom: 0;
        font-size: 25px;
      }
      .items{
        flex-direction: column;
      }
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 260px;
          height: 350px;
          margin: 50px 0 0;
        }
      }
      .right_item{
        margin-right: 0;
        width: calc(100vw - 40px);
        margin: 0 auto;
        padding-bottom: 50px;
        p{
          font-size: 18px;
        }
        .items{
          padding: 20px !important;
          text-align: center;
          img{
            margin-bottom: 20px;
          }
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_tips{
          width: calc(100vw - 40px);
          p{
            font-size: 12px !important;
            width: 70px;
          }
        }
      }
    }
    .inner3{
      padding: 50px 20px;
      height: 1150px;
      flex-direction: column;
      .left_item{
        position: relative;
        h1{
          font-size: 20px;
          margin-left: 0;
          margin-top: 80px;
          text-align: center;
          margin-bottom: 0;
        }
        p{
          text-align: center;
          margin-bottom: 20px;
        }
        div{
          position: absolute;
          top: 15px;
          img{
            width: 120px;
            height: 40px;
            margin: 0 auto 5px;
          }
        }
      }
      .right_item{
        margin-right: 0;
        width: calc(100vw - 40px);
        .item_info{
          background: #F8F9FF !important;
        }
        p{
          font-size: 18px;
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_info{
          flex-direction: column;
        }
        .info_tips{
          width: calc(100vw - 40px);
          margin-bottom: 20px;
          margin-right: 0px;
          p{
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
</style>
